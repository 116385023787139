<template>
  <div class="buttons-block is-flex is-justify-content-space-between " >
    <a href="" @click.prevent="open_row(1)">
			<i title="Modifiko" class="fa fa-pencil"></i>
		</a>
    <a href="" @click.prevent="open_row(0)">
			<i title="Shiko" class="fa fa-folder-open"></i>
		</a>
    <a v-if="params.data.is_recipe == 1" href="" @click.prevent="open_row(2)">
			<i title="Shto materiale per kete produkt" class="fa fa-list"></i>
		</a>
  </div>
</template>

<script>
export default {
    props: ["params"],
    data(){
        return {
            
        }
    },
    methods:{
        open_row(m){
            this.params.context.componentParent.open(m, this.params.data)
        }
    },
}
</script>

<style>
	.buttons-block {
		max-width: 70px;
		margin: auto;
	}
</style>

